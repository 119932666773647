import React, {Component} from 'react';
import SideNavbar from "./side_navbar";
import Container from "react-bootstrap/Container";
import {Accordion, Col, Row} from "react-bootstrap";
import {Avatar} from "@mui/material";
import './according_style.css'

class Privacy extends Component {

    constructor(props) {
        super(props);

        this.state ={
            profile: null
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            window.href.location = "/login";
        }

        this.getUserProfile()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://vect-ai.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({profile: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar(name) {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    render() {

        return (
            <Container fluid className="p-0 pb-5" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <SideNavbar
                    page_name={'PRIVACY'}
                />

                <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                    <Row>
                        <Col>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                Privacy & Policy
                            </div>
                        </Col>
                        <Col>
                            <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User'}</span>
                                <Avatar {...this.stringAvatar(this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User')} />

                            </div>
                        </Col>
                    </Row>

                    <Row className={'p-4'}>
                        <div
                            style={{fontSize: '15px', fontWeight: 'bold', color: 'white'}}>
                            Policy Of Ours
                        </div>

                        <div
                            style={{fontSize: '13px', color: 'white'}} className={'mt-4'}>
                            Amet nulla varius sed sed at ac pellentesque. Eget lacinia feugiat magna in ut. Enim adipiscing bibendum enim vestibulum ultrices eu facilisi leo enim. At nunc ut volutpat amet cursus donec donec sollicitudin. Turpis mauris nisl ornare maecenas urna egestas. Sagittis sit et eros varius est faucibus. A tincidunt turpis at vel. Tellus suspendisse eu sed neque quis quis diam tellus. Neque facilisis quisque sed urna cursus. Neque erat at tortor nulla porta.
                        </div>


                    </Row>
                </div>
            </Container>

        );
    }
}

export default Privacy;
