import Container from "react-bootstrap/Container";
import AuthenticationHeader from "./authentication_header";
import {Button, Card, Image, Row, Table} from "react-bootstrap";
import Footer from "./footer";
import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";


class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstname: null,
            lastname: null,
            email_address: null,
            password: null,
            confirm_password: null
        }

        this.inputRefs = {
            password: React.createRef(),
            confirm_password: React.createRef(),
            email_address: React.createRef(),
            lastname: React.createRef(),
            firstname: React.createRef(),

        };
    }

    componentDidMount() {
        if (localStorage.getItem('token') !== null) {
            window.location.href = '/panel';
        }
    }

    render() {
        return (
            <Container fluid className="p-0" style={{ height: '100%', backgroundColor: '#0F0F0F' }}>
                <ToastContainer/>
                <AuthenticationHeader/>
                <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <Card style={{width: '40rem', backgroundColor: '#171717'}}>
                        <Card.Body>
                            <Row className="d-flex justify-content-center">
                                <Image
                                    src="/logo192.png"
                                    fluid
                                    className="d-inline-block align-top mt-5"
                                    alt="Vect AI "
                                    style={{ width: '100px' }}
                                />
                            </Row>
                            <Row>
                                <div className={'justify-content-center align-items-center d-flex mt-3'}
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Register to our portal
                                </div>
                            </Row>
                            <Row className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                <Table size="sm" style={{border: 'none', background: 'none', width: '30rem'}}>

                                    <tbody style={{background: 'none'}}>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='text' placeholder='First Name' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.firstname}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='text' placeholder='Last Name' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.lastname}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='email' placeholder='Email Address' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.email_address}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='password' placeholder='Password' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.password}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='password' placeholder='Confirm Password' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.confirm_password}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <div className={'justify-content-center d-flex mt-4'}>
                                                <Button
                                                onClick={(e) => this.handleSubmit(e)}
                                                    style={{
                                                    borderRadius: 0,
                                                    background: '#0E5B22',
                                                    border: "none",
                                                    width: '100%'
                                                }}>Register</Button>
                                            </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
                <Footer/>
            </Container>
        );
    }

    handleSubmit = (e) => {
        // Access the value of the firstname input field
        const firstNameValue = this.inputRefs.firstname.current.value;
        const lastNameValue = this.inputRefs.lastname.current.value;
        const passwordValue = this.inputRefs.password.current.value;
        const confirmPasswordValue = this.inputRefs.confirm_password.current.value;
        const emailValue = this.inputRefs.email_address.current.value;

        if (passwordValue !== confirmPasswordValue) {
            toast.error('Passwords do not match', {
                position: 'top-left'
            });

        }else {
            if (firstNameValue === null ||
                lastNameValue === null ||
                passwordValue === null ||
                confirmPasswordValue === null ||
                emailValue === null) {

                toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                    position: 'top-left'
                });

            } else {
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        first_name: firstNameValue,
                        last_name: lastNameValue,
                        password: passwordValue,
                        email: emailValue
                    })
                };

                fetch('https://vect-ai.com/api/v1/register-user/', requestOptions)
                    .then((response) => {
                        if (!response.ok) throw response.status;
                        else return response.json();
                    })
                    .then((response) => {
                        if (response.status === 201) {
                            window.location.href = '/login'
                        }
                    })
                    .catch((error) => {

                        if (error === 201) {
                            window.location.href = '/login'

                        }
                        if (error === 400) {
                            toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                                position: 'top-left'
                            });
                        } else {
                            if (error === 406) {
                                toast.error('Oops! It seems like you’ve already registered with this email. You can log in or reset your password if you’ve forgotten it.', {
                                    position: 'top-left'
                                });
                            } else {
                                    if (error === 429) {
                                        toast.error('You\'ve hit the limit for requests. Please wait 10 minutes before trying again.', {
                                            position: 'top-left'
                                        });
                                    }

                            }
                        }

                    });
            }
        }

        // You can perform other operations like focusing inputs
        this.inputRefs.password.current.focus();
    }
}

export default Register;