import React, { Component } from 'react';
import SideNavbar from "./side_navbar";
import Container from "react-bootstrap/Container";
import {Card, Col, InputGroup, Row} from "react-bootstrap";
import {Avatar} from "@mui/material";
import Form from 'react-bootstrap/Form';
import {CiSearch} from "react-icons/ci";
import styled from './form_placeholder.css';
import {toast, ToastContainer} from "react-toastify";

class TechnicalQuery extends Component {

    constructor(props) {
        super(props);

        this.state ={
            profile: null,
            projects: null
        }
    }
    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            window.href.location = "/login";
        }

        this.getUserProfile()
        this.getProjectsList()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://vect-ai.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({profile: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    getProjectsList(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://vect-ai.com/api/v1/projects/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({projects: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar(name) {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    render() {
        if(this.state.projects === null) {
            return (
                <Container fluid className="p-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                    <SideNavbar
                        page_name={'TechnicalQuery'}
                    />

                    <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                        <Row>
                            <Col>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                    Technical Query
                                </div>
                            </Col>
                            <Col>
                                <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User'}</span>
                                    <Avatar {...this.stringAvatar(this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User')} />

                                </div>
                            </Col>
                        </Row>

                        <Row className={'mt-2'}>
                            <Col md={4}>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Choose Project
                                </div>
                            </Col>
                            <Col md={{ span: 3, offset: 4 }} className={'text-end'}>
                                <div className="justify-content-end align-items-end mt-4"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    {/*<Form inline>*/}
                                    {/*    <InputGroup>*/}
                                    {/*        <InputGroup.Text id="basic-addon1"*/}
                                    {/*                         style={{backgroundColor: 'transparent',*/}
                                    {/*                             borderColor: '#0E5B22'}}><CiSearch style={{color: 'white'}}/></InputGroup.Text>*/}
                                    {/*        <Form.Control*/}
                                    {/*            style={{backgroundColor: 'transparent',*/}
                                    {/*                borderColor:'#0E5B22', color: '#5A5C5F', }}*/}
                                    {/*            placeholder="Search By Project Name"*/}
                                    {/*            aria-label="Search"*/}
                                    {/*            aria-describedby="basic-addon1"*/}
                                    {/*        />*/}
                                    {/*    </InputGroup>*/}
                                    {/*</Form>*/}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className={'text-center justify-content-center align-items-center d-flex mt-5'}
                                 style={{color: 'white', fontSize: '13px'}}>
                                You don’t have any projects yet. Start by creating your first project!
                            </div>
                        </Row>


                    </div>
                </Container>
            );
        }
        return (
            <Container fluid className="p-0 pb-4" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <ToastContainer/>
                <SideNavbar
                    page_name={'TechnicalQuery'}
                />

                <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                    <Row>
                        <Col>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                Technical Query
                            </div>
                        </Col>
                        <Col>
                            <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User'}</span>
                                <Avatar {...this.stringAvatar(this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User')} />

                            </div>
                        </Col>
                    </Row>

                    <Row className={'mt-2'}>
                        <Col md={4}>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                Choose Project
                            </div>
                        </Col>
                        <Col md={{ span: 3, offset: 4 }} className={'text-end'}>
                            <div className="justify-content-end align-items-end mt-4"
                                 style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                {/*<Form inline>*/}
                                {/*    <InputGroup>*/}
                                {/*        <InputGroup.Text id="basic-addon1"*/}
                                {/*                         style={{backgroundColor: 'transparent',*/}
                                {/*                             borderColor: '#0E5B22'}}><CiSearch style={{color: 'white'}}/></InputGroup.Text>*/}
                                {/*        <Form.Control*/}
                                {/*            style={{backgroundColor: 'transparent',*/}
                                {/*                borderColor:'#0E5B22', color: '#5A5C5F', }}*/}
                                {/*            placeholder="Search By Project Name"*/}
                                {/*            aria-label="Search"*/}
                                {/*            aria-describedby="basic-addon1"*/}
                                {/*        />*/}
                                {/*    </InputGroup>*/}
                                {/*</Form>*/}

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.projects.map((item, index) => (
                            <Col xs={'auto'} key={index} onClick={(e) => this.clickOnDisabledProject(e, item.id, item.is_tq_processed, item.name)}>
                                <Card
                                    style={{
                                        width: '18rem',
                                        backgroundColor: 'transparent',
                                        opacity: item.is_tq_processed ? 1 : 0.5, // Dim the card if not completed
                                        pointerEvents: item.is_tq_processed ? 'auto' : 'none' // Disable interactions if not completed
                                    }}
                                    className={'mt-4'}
                                >
                                    <div className={'text-center'}>
                                        <Card.Img variant="center" src="images/ppl.png" style={{ width: '40%' }} />
                                    </div>
                                    <Card.Body style={{ backgroundColor: '#1F1F1F' }}>
                                        {item.is_tq_processed ? (
                                            <a href={`/technical-query-chat?id=${item.id}`} style={{ textDecoration: 'none' }}>
                                                <Card.Text
                                                    style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }}
                                                    className={'p-0 m-0'}
                                                >
                                                    {item.name}
                                                </Card.Text>
                                            </a>
                                        ) : (
                                            <Card.Text
                                                style={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }}
                                                className={'p-0 m-0'}
                                            >
                                                {item.name}
                                            </Card.Text>
                                        )}
                                        <Card.Text
                                            style={{ color: 'white', fontSize: '15px' }}
                                            className={'p-0 m-0'}
                                        >
                                            {item.create_at}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>


                </div>
            </Container>

        );
    }
    clickOnDisabledProject(e, projectId, is_tq_processed, projectName) {
        if(is_tq_processed || is_tq_processed === undefined) {
            window.location.href = `/technical-query-chat?id=${projectId}`;
        }else {
            toast.info('You cannot access this project yet. Please wait for the technical queries to be processed.', {
                position: 'top-left'
            });

        }

    }
}

export default TechnicalQuery;
