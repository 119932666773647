import Container from "react-bootstrap/Container";
import {Button, Card, Image, Row, Table} from "react-bootstrap";
import React, {Component} from "react";
import {toast, ToastContainer} from "react-toastify";
import Footer from "../footer";
import UserProfileHeader from "./user_profile_header";


class UserProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstname: null,
            lastname: null,
            email_address: null,
        }

        this.inputRefs = {
            email_address: React.createRef(),
            lastname: React.createRef(),
            firstname: React.createRef(),

        };
    }

    componentDidMount() {
        this.getUserProfile();
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://vect-ai.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({
                    firstname: response.first_name,
                    lastname: response.last_name,
                    email: response.email,
                });


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    render() {
        if(this.state.first_name === null || this.state.last_name === null || this.state.email === null){
            return (
                <div>
                    Loading...
                </div>
            )
        }
        return (
            <Container fluid className="p-0" style={{ height: '100%', backgroundColor: '#0F0F0F' }}>
                <ToastContainer/>
                <UserProfileHeader/>
                <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <Card style={{width: '40rem', backgroundColor: '#171717'}}>
                        <Card.Body>
                            <Row className="d-flex justify-content-center">
                                <Image
                                    src="/logo192.png"
                                    fluid
                                    className="d-inline-block align-top mt-5"
                                    alt="Vect AI "
                                    style={{ width: '100px' }}
                                />
                            </Row>
                            <Row>
                                <div className={'justify-content-center align-items-center d-flex mt-3'}
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Edit User Profile Details
                                </div>
                            </Row>
                            <Row className="d-flex justify-content-center align-items-center mt-4 mb-4">
                                <Table size="sm" style={{border: 'none', background: 'none', width: '30rem'}}>

                                    <tbody style={{background: 'none'}}>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='text' placeholder='First Name' required={true}
                                                   className={'p-3'}
                                                   ref={this.inputRefs.firstname}
                                                   onChange={this.handleFirstNameInputChange}
                                                   value={this.state.firstname}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='text' placeholder='Last Name' required={true}
                                                   className={'p-3'}
                                                   value={this.state.lastname}
                                                   ref={this.inputRefs.lastname}
                                                   onChange={this.handleLastNameInputChange}
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <input type='email' placeholder={this.state.email} required={true}
                                                   className={'p-3'}

                                                   disabled
                                                   style={{
                                                       color: 'white',
                                                       background: '#D9D9D91A',
                                                       border: 0,
                                                       width: '100%',
                                                       height: '3.1rem'
                                                   }}/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{border: 'none', backgroundColor: 'transparent'}}>
                                            <div className={'justify-content-center d-flex mt-4'}>
                                                <Button
                                                    onClick={(e) => this.handleSubmit(e)}
                                                    style={{
                                                        borderRadius: 0,
                                                        background: '#0E5B22',
                                                        border: "none",
                                                        width: '100%'
                                                    }}>Edit & Save</Button>
                                            </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
                <Footer/>
            </Container>
        );
    }

    handleLastNameInputChange = (e) => {
        this.setState({ lastname: e.target.value });
    }

    handleFirstNameInputChange = (e) => {
        this.setState({ firstname: e.target.value });
    }

    handleSubmit = (e) => {
        // Access the value of the firstname input field
        const firstNameValue = this.inputRefs.firstname.current.value;
        const lastNameValue = this.inputRefs.lastname.current.value;



        if (firstNameValue === null ||
            lastNameValue === null) {

            toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                position: 'top-left'
            });

        } else {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                },
                body: JSON.stringify({
                    first_name: firstNameValue,
                    last_name: lastNameValue
                })
            };

            fetch('https://vect-ai.com/api/v1/profile-update/', requestOptions)
                .then((response) => {
                    if (!response.ok) throw response.status;
                    else return response.json();
                })
                .then((response) => {
                    if (response.status === 202) {
                        toast.info('Your profile has been updated', {
                            position: 'top-left'
                        });
                        this.getUserProfile()
                    }
                })
                .catch((error) => {


                    if (error === 400) {
                        toast.error('Oops! Looks like you missed a field. Please double-check all fields.', {
                            position: 'top-left'
                        });
                    } else {

                        if (error === 429) {
                            toast.error('You\'ve hit the limit for requests. Please wait 10 minutes before trying again.', {
                                position: 'top-left'
                            });
                        }else {
                            window.location.href = '/login'
                        }


                    }

                });
        }

    }
}

export default UserProfile;