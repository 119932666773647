import React, { Component } from 'react';
import SideNavbar from "./side_navbar";
import Container from "react-bootstrap/Container";
import {Button, Card, Col, Image, Row, Tab, Table, Tabs} from "react-bootstrap";
import {Avatar} from "@mui/material";
import styled from './form_placeholder.css';
import './FileUploadBox.css';
import './activeTabStyle.css';
import './overlay.css';
import {toast, ToastContainer} from "react-toastify";
import {Grid, ProgressBar} from "react-loader-spinner";
import {IoIosCloudDone} from "react-icons/io";

class AddProject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDragging: false,
            fileName: "",
            file: null,
            showResults: false,
            key: 'Project_Information',
            is_uploading: false,
            is_processing: false,
            intervalId: null,
            upserted_data: null,
            profile: null,
            msg_text: 'Loading',
            project_name: null,
            number_of_files: null,
            current_processing_file: null

        }
        this.fileInputRef = React.createRef();  // Create a ref for the file input
        this.project_nameRef = React.createRef();  // Create a ref for the file input
        this.project_DescriptionRef = React.createRef();  // Create a ref for the file input

        this.intervalDuration = 5000;
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            window.location.href = "/login";
        }
        this.getUserProfile()
    }

    getUserProfile(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('token'),
            },
        };
        fetch('https://vect-ai.com/api/v1/profile/', requestOptions)

            .then((response) => {
                if(!response.ok) throw response.status;
                else return response.json();
            })
            .then((response) => {

                this.setState({profile: response});


            })
            .catch((error) => {
                localStorage.removeItem('token');
                window.location.href = '/login';

            });
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ fileName: file.name, file: file });
        }
    };

    handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isDragging: true });
    };

    handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isDragging: false });
    };

    handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ isDragging: false });

        const files = event.dataTransfer.files;

        if (files.length > 0) {
            const file = files[0];
            this.setState({ fileName: file.name, file: file });
            // Trigger file input change event
            this.fileInputRef.current.files = files;
            this.handleFileChange({ target: { files: files } });  // Manually trigger the file change handler
        }
    };

    handleClick = () => {
        // Programmatically trigger the file input click
        this.fileInputRef.current.click();
    };

    stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar(name) {
        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    uploadUI(){
        if(this.state.is_uploading){
            return (
                <Container fluid className="p-0" style={{minHeight: '100vh', backgroundColor: '#0F0F0F'}}>
                    <ToastContainer/>
                    <SideNavbar
                        page_name={'Projects'}
                    />

                    <div style={{
                        marginLeft: '300px',
                        padding: '0px 10px',
                        backgroundColor: '#0F0F0F',
                        minHeight: '100vh'
                    }}>
                        <Row style={{width: '100%'}}>
                            <Col>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Adding New Project
                                </div>
                            </Col>
                            <Col style={{marginRight: '20px'}}>
                                <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User'}</span>
                                    <Avatar {...this.stringAvatar(this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User')} />

                                </div>
                            </Col>
                        </Row>
                        <Row className={'mb-4'} style={{width: '90%'}}>
                            <Col xs={"auto"}>
                                <Card style={{
                                    width: '25rem',
                                    height: '100%',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    pointerEvents: 'none',
                                    opacity: '0.5'
                                }}>
                                    <Card.Body>
                                        <div className={'overlay'}>
                                            <div>
                                                <ProgressBar
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    barColor='#0E5B22'
                                                    borderColor='#ffffff'
                                                    ariaLabel="progress-bar-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                                <div>
                                                    Uploading ...
                                                </div>
                                            </div>
                                        </div>
                                        <Row className={'m-4'}>
                                            <Col xs={'auto'}>

                                                <Image
                                                    src="images/upload_cloud.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Vect AI "
                                                    style={{width: '45px'}}
                                                />

                                            </Col>
                                            <Col xs={'auto'}
                                                 className={'text-start justify-content-start align-items-start d-flex'}>
                                                <div className={'text-start'}
                                                     style={{fontSize: '15px', fontWeight: 'bold'}}>
                                                    Upload file
                                                    <br/>
                                                    <div className={'text-start mt-2'}
                                                         style={{
                                                             fontSize: '12px',
                                                             fontWeight: 'bold',
                                                             color: '#A9ACB4'
                                                         }}>
                                                        Select and upload a zip file of your choice
                                                    </div>
                                                </div>

                                            </Col>
                                            <hr className={'mt-2'} style={{hight: '15px', color: '#CBD0DC'}}/>
                                        </Row>
                                        <Row className={'m-4'}>
                                            <input type='text' placeholder={'Project Name'} required

                                                   style={{
                                                       width: '100%',
                                                       height: '50px',
                                                       border: 'none',
                                                       backgroundColor: '#D9D9D93D',
                                                       fontSize: '13px'
                                                   }}/>

                                            <textarea placeholder={'Project Details'} required
                                                      rows={5}
                                                      className={'mt-2 p-2'}

                                                      style={{
                                                          width: '100%',
                                                          height: '70px',
                                                          resize: 'none',
                                                          border: 'none',
                                                          backgroundColor: '#D9D9D93D',
                                                          fontSize: '13px'
                                                      }}/>
                                        </Row>
                                        <Row className={'m-4'}>
                                            <div
                                                className={`file-upload-box ${this.state.isDragging ? "active" : ""}`}

                                            >
                                                <input
                                                    type="file"

                                                    className="file-input"
                                                />
                                                <Image
                                                    src="images/upload_cloud2.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Upload Icon"
                                                    style={{width: '30px'}}
                                                />
                                                <label className="file-label">
                                                    {this.state.fileName ? this.state.fileName : "Choose a zip file or drag & drop it here"}
                                                </label>
                                            </div>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={5} style={{marginLeft: '40px'}}
                                 className={'d-flex justify-content-center align-items-center'}>
                                <div className={'text-center'}>
                                    <Row className={'d-flex justify-content-center align-items-center'}>
                                        <Image
                                            src="images/upload_left.png"
                                            fluid
                                            className="d-inline-block align-center"
                                            alt="Upload Icon"
                                            style={{width: '450px'}}
                                        />
                                    </Row>
                                    <Row className={'d-flex justify-content-start align-items-start'}>
                                        <Button
                                            disabled={true}
                                            style={{
                                                marginTop: '20px',
                                                marginLeft: '13px',
                                                borderRadius: '0',
                                                background: '#0E5B22',
                                                borderColor: '#0E5B22',
                                                width: '55%', fontSize: '12px',
                                                height: '40px'
                                            }}>Save Project & Get Summary</Button>
                                    </Row>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>

            );
        }else {
            return (
                <Container fluid className="p-0" style={{minHeight: '100vh', backgroundColor: '#0F0F0F'}}>
                    <ToastContainer/>
                    <SideNavbar
                        page_name={'Projects'}
                    />

                    <div style={{
                        marginLeft: '300px',
                        padding: '0px 10px',
                        backgroundColor: '#0F0F0F',
                        minHeight: '100vh'
                    }}>
                        <Row style={{width: '100%'}}>
                            <Col>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{fontWeight: 'bold', fontSize: '13px', color: 'white'}}>
                                    Adding New Project
                                </div>
                            </Col>
                            <Col style={{marginRight: '20px'}}>
                                <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User'}</span>
                                    <Avatar {...this.stringAvatar(this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User')} />

                                </div>
                            </Col>
                        </Row>
                        <Row className={'mb-4'} style={{width: '90%'}}>
                            <Col xs={"auto"}>
                                <Card style={{width: '25rem', height: '100%', backgroundColor: 'white'}}>
                                    <Card.Body>
                                        <Row className={'m-4'}>
                                            <Col xs={'auto'}>

                                                <Image
                                                    src="images/upload_cloud.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Vect AI "
                                                    style={{width: '45px'}}
                                                />

                                            </Col>
                                            <Col xs={'auto'}
                                                 className={'text-start justify-content-start align-items-start d-flex'}>
                                                <div className={'text-start'}
                                                     style={{fontSize: '15px', fontWeight: 'bold'}}>
                                                    Upload file
                                                    <br/>
                                                    <div className={'text-start mt-2'}
                                                         style={{
                                                             fontSize: '12px',
                                                             fontWeight: 'bold',
                                                             color: '#A9ACB4'
                                                         }}>
                                                        Select and upload a zip file of your choice
                                                    </div>
                                                </div>

                                            </Col>
                                            <hr className={'mt-2'} style={{hight: '15px', color: '#CBD0DC'}}/>
                                        </Row>
                                        <Row className={'m-4'}>
                                            <input type='text' placeholder={'Project Name'} required
                                                   ref={this.project_nameRef}
                                                   style={{
                                                       width: '100%',
                                                       height: '50px',
                                                       border: 'none',
                                                       backgroundColor: '#D9D9D93D',
                                                       fontSize: '13px'
                                                   }}/>

                                            <textarea placeholder={'Project Details'} required
                                                      rows={5}
                                                      className={'mt-2 p-2'}
                                                      ref={this.project_DescriptionRef}
                                                      style={{
                                                          width: '100%',
                                                          height: '70px',
                                                          resize: 'none',
                                                          border: 'none',
                                                          backgroundColor: '#D9D9D93D',
                                                          fontSize: '13px'
                                                      }}/>
                                        </Row>
                                        <Row className={'m-4'}>
                                            <div
                                                className={`file-upload-box ${this.state.isDragging ? "active" : ""}`}
                                                onClick={this.handleClick}
                                                onDragOver={this.handleDragOver}
                                                onDragLeave={this.handleDragLeave}
                                                onDrop={this.handleDrop}
                                            >
                                                <input
                                                    type="file"
                                                    ref={this.fileInputRef}  // Attach the ref to the file input
                                                    onChange={this.handleFileChange}
                                                    className="file-input"
                                                />
                                                <Image
                                                    src="images/upload_cloud2.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Upload Icon"
                                                    style={{width: '30px'}}
                                                />
                                                <label className="file-label">
                                                    {this.state.fileName ? this.state.fileName : "Choose a zip file or drag & drop it here"}
                                                </label>
                                            </div>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={5} style={{marginLeft: '40px'}}
                                 className={'d-flex justify-content-center align-items-center'}>
                                <div className={'text-center'}>
                                    <Row className={'d-flex justify-content-center align-items-center'}>
                                        <Image
                                            src="images/upload_left.png"
                                            fluid
                                            className="d-inline-block align-center"
                                            alt="Upload Icon"
                                            style={{width: '450px'}}
                                        />
                                    </Row>
                                    <Row className={'d-flex justify-content-start align-items-start'}>
                                        <Button
                                            onClick={(e) => this.submitProject(e)}
                                            style={{
                                                marginTop: '20px',
                                                marginLeft: '13px',
                                                borderRadius: '0',
                                                background: '#0E5B22',
                                                borderColor: '#0E5B22',
                                                width: '55%', fontSize: '12px',
                                                height: '40px'
                                            }}>Save Project & Get Summary</Button>
                                    </Row>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Container>

            );
        }
    }

    showResultsUI(){
        if(this.state.is_processing){
            return (
                <Container fluid className="p-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                    <SideNavbar
                        page_name={'Projects'}
                    />

                    <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                        <Row style={{ width: '100%'}}>
                            <Col>
                                <div className="justify-content-start align-items-start mt-4"
                                     style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                    Adding New Project
                                </div>
                            </Col>
                            <Col style={{marginRight: '20px'}}>
                                <div className="justify-content-end align-items-end d-flex m-3">
                                <span className={'mb-2'} style={{
                                    color: 'white',
                                    marginRight: '10px'
                                }}>{this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User'}</span>
                                    <Avatar {...this.stringAvatar(this.state.profile !== null ? (this.state.profile.first_name + ' ' + this.state.profile.last_name) : 'User User')} />

                                </div>
                            </Col>
                        </Row>
                        <Row className={'mb-4'} style={{ width: '90%'}}>
                            <Col xs={"auto"} >
                                <Card style={{
                                    width: '25rem',
                                    height: '100%',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    pointerEvents: 'none',
                                    opacity: '0.5'
                                }}>
                                    <Card.Body>
                                        <div className={'overlay'}>
                                            <IoIosCloudDone style={{fontSize: '220px', color: '#0E5B224D'}}/>
                                        </div>
                                        <Row className={'m-4'}>
                                            <Col xs={'auto'}>

                                                <Image
                                                    src="images/upload_cloud.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Vect AI "
                                                    style={{width: '45px'}}
                                                />

                                            </Col>
                                            <Col xs={'auto'}
                                                 className={'text-start justify-content-start align-items-start d-flex'}>
                                                <div className={'text-start'}
                                                     style={{fontSize: '15px', fontWeight: 'bold'}}>
                                                    Upload file
                                                    <br/>
                                                    <div className={'text-start mt-2'}
                                                         style={{
                                                             fontSize: '12px',
                                                             fontWeight: 'bold',
                                                             color: '#A9ACB4'
                                                         }}>
                                                        Select and upload a zip file of your choice
                                                    </div>
                                                </div>

                                            </Col>
                                            <hr className={'mt-2'} style={{hight: '15px', color: '#CBD0DC'}}/>
                                        </Row>
                                        <Row className={'m-4'}>
                                            <input type='text' placeholder={'Project Name'} required
                                                   style={{
                                                       width: '100%',
                                                       height: '50px',
                                                       border: 'none',
                                                       backgroundColor: '#D9D9D93D',
                                                       fontSize: '13px'
                                                   }}/>

                                            <textarea placeholder={'Project Details'} required
                                                      rows={5}
                                                      className={'mt-2 p-2'}
                                                      style={{
                                                          width: '100%',
                                                          height: '70px',
                                                          resize: 'none',
                                                          border: 'none',
                                                          backgroundColor: '#D9D9D93D',
                                                          fontSize: '13px'
                                                      }}/>
                                        </Row>
                                        <Row className={'m-4'}>
                                            <div
                                                className={`file-upload-box ${this.state.isDragging ? "active" : ""}`}

                                            >
                                                <input
                                                    type="file"

                                                    onChange={this.handleFileChange}
                                                    className="file-input"
                                                />
                                                <Image
                                                    src="images/upload_cloud2.png"
                                                    fluid
                                                    className="d-inline-block align-top"
                                                    alt="Upload Icon"
                                                    style={{width: '30px'}}
                                                />
                                                <label className="file-label">
                                                    {this.state.fileName ? this.state.fileName : "Choose a zip file or drag & drop it here"}
                                                </label>
                                            </div>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={6} style={{marginLeft: '40px', position: 'relative',
                                pointerEvents: 'none',
                                opacity: '0.5'}}
                                 className={'d-flex justify-content-center align-items-center'}>
                                <div className={'overlay'}>
                                    <div>
                                        <Grid
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#4fa94d"
                                            ariaLabel="grid-loading"
                                            radius="12.5"
                                            wrapperStyle={{}}
                                            wrapperClass="grid-wrapper"
                                        />

                                        <div>
                                            {this.state.msg_text} ...
                                        </div>
                                    </div>
                                </div>
                                {this.showResultsTabs()}
                            </Col>

                        </Row>
                    </div>
                </Container>

            );
        }
        return (
            <Container fluid className="p-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <SideNavbar
                    page_name={'Projects'}
                />

                <div style={{ marginLeft: '300px', padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }}>
                    <Row style={{ width: '100%'}}>
                        <Col>
                            <div className="justify-content-start align-items-start mt-4"
                                 style={{ fontWeight: 'bold', fontSize: '13px', color: 'white' }}>
                                Adding New Project
                            </div>
                        </Col>
                        <Col style={{marginRight: '20px'}}>
                            <div className="justify-content-end align-items-end d-flex m-3">
                                <Avatar {...this.stringAvatar('Khashayar Gh')} />
                            </div>
                        </Col>
                    </Row>
                    <Row className={'mb-4'} style={{ width: '90%'}}>
                        <Col xs={"auto"} >
                            <Card style={{
                                width: '25rem',
                                height: '100%',
                                backgroundColor: 'white',
                                position: 'relative',
                                pointerEvents: 'none',
                                opacity: '0.5'
                            }}>
                                <Card.Body>
                                    <div className={'overlay'}>
                                        <IoIosCloudDone style={{fontSize: '220px', color: '#0E5B224D'}}/>
                                    </div>
                                    <Row className={'m-4'}>
                                        <Col xs={'auto'}>

                                            <Image
                                                src="images/upload_cloud.png"
                                                fluid
                                                className="d-inline-block align-top"
                                                alt="Vect AI "
                                                style={{ width: '45px' }}
                                            />

                                        </Col>
                                        <Col xs={'auto'}
                                             className={'text-start justify-content-start align-items-start d-flex'}>
                                            <div className={'text-start'}
                                                 style={{fontSize: '15px', fontWeight: 'bold'}}>
                                                Upload file
                                                <br/>
                                                <div className={'text-start mt-2'}
                                                     style={{fontSize: '12px', fontWeight: 'bold', color: '#A9ACB4'}}>
                                                    Select and upload a zip file of your choice
                                                </div>
                                            </div>

                                        </Col>
                                        <hr className={'mt-2'} style={{hight: '15px', color: '#CBD0DC'}}/>
                                    </Row>
                                    <Row className={'m-4'}>
                                        <input type='text' placeholder={'Project Name'} required
                                               style={{
                                                   width: '100%',
                                                   height: '50px',
                                                   border: 'none',
                                                   backgroundColor: '#D9D9D93D',
                                                   fontSize: '13px'
                                               }}/>

                                        <textarea placeholder={'Project Details'} required
                                                  rows={5}
                                                  className={'mt-2 p-2'}
                                                  style={{
                                                      width: '100%',
                                                      height: '70px',
                                                      resize: 'none',
                                                      border: 'none',
                                                      backgroundColor: '#D9D9D93D',
                                                      fontSize: '13px'
                                                  }}/>
                                    </Row>
                                    <Row className={'m-4'}>
                                        <div
                                            className={`file-upload-box ${this.state.isDragging ? "active" : ""}`}
                                            onClick={this.handleClick}
                                            onDragOver={this.handleDragOver}
                                            onDragLeave={this.handleDragLeave}
                                            onDrop={this.handleDrop}
                                        >
                                            <input
                                                type="file"


                                                className="file-input"
                                            />
                                            <Image
                                                src="images/upload_cloud2.png"
                                                fluid
                                                className="d-inline-block align-top"
                                                alt="Upload Icon"
                                                style={{width: '30px'}}
                                            />
                                            <label className="file-label" >
                                                {this.state.fileName ? this.state.fileName : "Choose a zip file or drag & drop it here"}
                                            </label>
                                        </div>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6} style={{marginLeft: '40px'}} className={'d-flex justify-content-center align-items-center'}>
                            {this.showResultsTabs()}
                        </Col>

                    </Row>
                </div>
            </Container>

        );
    }
    render() {
        if(this.state.showResults){
            return this.showResultsUI()
        }

        return this.uploadUI()

    }

    handleTabSelection = (k) => {
        // Update state with the new tab key
        this.setState({ key: k });
    }

    showResultsTabs(){
        if(this.state.is_processing){
            return (
                <div style={{backgroundColor: '#1A1A1A', width: '100%', height: '100%'}}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={(k) => this.handleTabSelection(k)}
                        className="m-4 custom-active-tab"
                    >
                        <Tab eventKey="Project_Information" title="Project Information">
                            <div className={'m-4'}>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Name</span>
                                <br/>
                                <span style={{color: '#FFFFFF', fontWeight: 'bold'}} className={'mt-3'}></span>
                                <br/>
                                <br/>
                                <span style={{color: '#8993A4', fontSize: '14px'}}></span>
                                <br/>
                                <br/>
                                <div  style={{
                                    color: '#fff',
                                    textAlign: 'justify',
                                    height: '200px',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    padding: '10px',
                                }}>

                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Project_Summary" title="Project Summary By AI">
                            <div className={'m-4'}>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Summary</span>
                                <br/>
                                <br/>
                                <div className={'scrollable-div'} style={{
                                    color: '#fff',
                                    textAlign: 'justify',
                                    height: '400px',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    padding: '10px',
                                }}>

                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            )
        }

        if(this.state.upserted_data !== null) {
            return (
                <div style={{backgroundColor: '#1A1A1A', width: '100%', height: '100%'}}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={(k) => this.handleTabSelection(k)}
                        className="m-4 custom-active-tab"
                    >
                        <Tab eventKey="Project_Information" title="Project Information">
                            <div className={'m-4'}>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Name</span>
                                <br/>
                                <span style={{color: '#FFFFFF', fontWeight: 'bold'}}
                                      className={'mt-3'}>{this.state.upserted_data.data.name}</span>
                                <br/>
                                <br/>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Description</span>
                                <br/>
                                <br/>
                                <div style={{
                                    color: '#fff',
                                    textAlign: 'justify',
                                    height: '200px',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    padding: '10px',
                                }}>
                                    {this.state.upserted_data.data.project_information}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Project_Summary" title="Project Summary By AI">
                            <div className={'m-4'}>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Summary</span>
                                <br/>
                                <br/>
                                <div className={'scrollable-div'} style={{
                                    color: '#fff',
                                    textAlign: 'justify',
                                    height: '400px',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    padding: '10px',
                                }}>
                                    {this.state.upserted_data.data.project_summary}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            )
        }else {
            if(this.state.number_of_files !== null && this.state.project_name !== null){
                if(this.state.current_processing_file !== null){
                    return (
                        <div style={{backgroundColor: '#1A1A1A', width: '100%', height: '100%'}}>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.state.key}
                                onSelect={(k) => this.handleTabSelection(k)}
                                className="m-4 custom-active-tab"
                            >
                                <Tab eventKey="Project_Information" title="Project Information">
                                    <div className={'m-4'}>
                                        <span style={{color: '#8993A4', fontSize: '14px'}}>Project Name</span>
                                        <br/>
                                        <span style={{color: '#FFFFFF', fontWeight: 'bold'}}
                                              className={'mt-3'}>{this.state.project_name}</span>
                                        <br/>
                                        <br/>
                                        <span style={{color: '#8993A4', fontSize: '14px'}}>Project Description</span>
                                        <br/>
                                        <br/>
                                        <div style={{
                                            color: '#fff',
                                            textAlign: 'justify',
                                            height: '200px',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            padding: '10px',
                                        }}>
                                            <span style={{fontWeight: 'bold'}}>
                                                {this.state.current_processing_file} of {this.state.number_of_files} files are being processed.
                                                </span>
                                            <br/>
                                            If you want to get the summary of the project, please wait until we process
                                            and
                                            analyse your files otherwise you can go to the Query Tool page for
                                            interacting your AI agent. But, the answers will be limited to the processed files.

                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Project_Summary" title="Project Summary By AI" >
                                    <div className={'m-4'}>
                                        <span style={{color: '#8993A4', fontSize: '14px'}}>Project Summary</span>
                                        <br/>
                                        <br/>
                                        <div className={'scrollable-div'} style={{
                                            color: '#fff',
                                            textAlign: 'justify',
                                            height: '400px',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            padding: '10px',
                                        }}>
                                            We are processing your files.
                                            <br/>
                                            If you want to get the summary of the project, please wait until we process
                                            and
                                            analyse your files otherwise you can go to the Query Tool page for
                                            interacting your AI agent.
                                            <br/>

                                            <div>
                                                <ProgressBar
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    barColor='#0E5B22'
                                                    borderColor='#ffffff'
                                                    ariaLabel="progress-bar-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    )
                }else {
                    return (
                        <div style={{backgroundColor: '#1A1A1A', width: '100%', height: '100%'}}>
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.state.key}
                                onSelect={(k) => this.handleTabSelection(k)}
                                className="m-4 custom-active-tab"
                            >
                                <Tab eventKey="Project_Information" title="Project Information">
                                    <div className={'m-4'}>
                                        <span style={{color: '#8993A4', fontSize: '14px'}}>Project Name</span>
                                        <br/>
                                        <span style={{color: '#FFFFFF', fontWeight: 'bold'}}
                                              className={'mt-3'}>{this.state.project_name}</span>
                                        <br/>
                                        <br/>
                                        <span style={{color: '#8993A4', fontSize: '14px'}}>Project Description</span>
                                        <br/>
                                        <br/>
                                        <div style={{
                                            color: '#fff',
                                            textAlign: 'justify',
                                            height: '200px',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            padding: '10px',
                                        }}>
                                            <span
                                                style={{fontWeight: 'bold'}}>{this.state.number_of_files}</span> file(s)
                                            are being loaded for the project <span
                                            style={{fontWeight: 'bold'}}>{this.state.project_name}</span>

                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Project_Summary" title="Project Summary By AI">
                                    <div className={'m-4'}>
                                        <span style={{color: '#8993A4', fontSize: '14px'}}>Project Summary</span>
                                        <br/>
                                        <br/>
                                        <div className={'scrollable-div'} style={{
                                            color: '#fff',
                                            textAlign: 'justify',
                                            height: '400px',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                            padding: '10px',
                                        }}>
                                            We are processing your files.
                                            <br/>
                                            If you want to get the summary of the project, please wait until we process
                                            and
                                            analyse your files otherwise you can go to the Query Tool page for
                                            interacting your AI agent.
                                            <br/>

                                            <div>
                                                <ProgressBar
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    barColor='#0E5B22'
                                                    borderColor='#ffffff'
                                                    ariaLabel="progress-bar-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    )
                }
            }
            return (
                <div style={{backgroundColor: '#1A1A1A', width: '100%', height: '100%'}}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.key}
                        onSelect={(k) => this.handleTabSelection(k)}
                        className="m-4 custom-active-tab"
                    >
                        <Tab eventKey="Project_Information" title="Project Information">
                            <div className={'m-4'}>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Name</span>
                                <br/>
                                <span style={{color: '#FFFFFF', fontWeight: 'bold'}}
                                      className={'mt-3'}>Nothing</span>
                                <br/>
                                <br/>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Description</span>
                                <br/>
                                <br/>
                                <div style={{
                                    color: '#fff',
                                    textAlign: 'justify',
                                    height: '200px',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    padding: '10px',
                                }}>
                                    Nothing
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="Project_Summary" title="Project Summary By AI">
                            <div className={'m-4'}>
                                <span style={{color: '#8993A4', fontSize: '14px'}}>Project Summary</span>
                                <br/>
                                <br/>
                                <div className={'scrollable-div'} style={{
                                    color: '#fff',
                                    textAlign: 'justify',
                                    height: '400px',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    padding: '10px',
                                }}>
                                    Nothing
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            )
        }

    }


    async submitProject(e) {
        e.preventDefault();

        const name = this.project_nameRef.current.value;
        const description = this.project_DescriptionRef.current.value;
        const file = this.fileInputRef.current.files[0];

        if (!name || !description || !file) {
            toast.error("Please fill all the fields and upload a file", {
                position: "top-right"
            });
            return;
        }

        const chunkSize = 5 * 1024 * 1024; // 5MB chunk size
        const totalChunks = Math.ceil(file.size / chunkSize);
        const uploadId = Date.now(); // Unique ID for this upload session

        this.setState({ is_uploading: true, showResults: false });

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            console.log(`Uploading chunk ${chunkIndex + 1}/${totalChunks}...`);
            const start = chunkIndex * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('file', chunk); // Append the chunk
            formData.append('project_name', name);
            formData.append('project_description', description);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('uploadId', uploadId); // Unique identifier for this upload

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
                body: formData
            };

            try {
                const response = await fetch('https://vect-ai.com/api/v1/upload/', requestOptions);

                if (!response.ok) {
                    throw new Error(`Failed at chunk ${chunkIndex}: ${response.status}`);
                }
                const result = await response.json();

                if (result.status === 202) {
                    this.setState({ is_uploading: false, is_processing: true, showResults: true });
                    this.startInterval(result.id);
                }

                console.log(`Chunk ${chunkIndex + 1}/${totalChunks} uploaded successfully.`);
            } catch (error) {
                toast.error(`Error uploading chunk ${chunkIndex + 1}: ${error.message}`, {
                    position: "top-right"
                });
                this.setState({ is_uploading: false });
                return; // Stop if an error occurs in any chunk
            }
        }


        console.log("All chunks uploaded successfully. File is being processed.");

        // Optional: Start polling or any other post-upload actions here if needed.
    }


    // async submitProject(e) {
    //     e.preventDefault();
    //
    //     const name = this.project_nameRef.current.value;
    //     const description = this.project_DescriptionRef.current.value;
    //     // const file = this.fileInputRef.current.files[0];
    //     const file = this.fileInputRef.current.files[0];
    //     // const file = this.state.file;
    //
    //     if (!name || !description || !file) {
    //         toast.error("Please fill all the fields and upload a file", {
    //             position: "top-right"
    //         });
    //         return; // Exit function if validation fails
    //     }
    //
    //     const formData = new FormData();
    //
    //     // Append the file to the FormData object
    //     formData.append('file', file); // Assuming `file` is a File object from file input
    //
    //     // Append other fields to FormData
    //     formData.append('project_name', name);
    //     formData.append('project_description', description);
    //
    //     this.setState({ is_uploading: true, showResults: false });
    //
    //     const requestOptions = {
    //         method: 'PUT',
    //         headers: {
    //             'Authorization': 'Bearer '+ localStorage.getItem('token'),
    //         },
    //         body: formData // Do not set 'Content-Type' header
    //     };
    //
    //     try {
    //         const response = await fetch('https://vect-ai.com/api/v1/upload/', requestOptions);
    //
    //         if (!response.ok) {
    //             throw response.status;
    //         }
    //
    //         const result = await response.json();
    //
    //         // Handle server response
    //         if (result.status === 202) {
    //             this.setState({ is_uploading: false, is_processing: true, showResults: true });
    //
    //             this.startInterval(result.id);
    //         } else {
    //             this.setState({ is_uploading: false }); // Handle other statuses if needed
    //         }
    //
    //         console.log(result); // Handle success response here
    //     } catch (error) {
    //         if (error===429) {
    //             toast.error('You\'ve hit the limit for requests. Please wait 10 minutes before trying again.', {
    //                 position: 'top-right'
    //             });
    //         } else {
    //             toast.error('An error occurred while uploading. Please try again.', {
    //                 position: 'top-right'
    //             });
    //         }
    //         // Ensure `is_uploading` is set to false even if an error occurs
    //         this.setState({ is_uploading: false });
    //     }
    //
    // }


    async callSecondApi(id) {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ localStorage.getItem('token'),
                }

            };
            const params = new URLSearchParams({
                ztf: id
            }).toString();
            // Replace with your second API endpoint
            const response = await fetch(`https://vect-ai.com/api/v1/upsert-status?${params}`, requestOptions);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            if(data.status === 'processed') {
                this.setState({is_uploading: false, is_processing: false, showResults: true, upserted_data: data});
                clearInterval(this.state.intervalId);
            }else {
                if(data.status === 'Analysis'){
                    this.setState({
                        msg_text: data.msg,
                        is_uploading: false,
                        is_processing: false,
                        showResults: true,
                        number_of_files: data.data.number_of_pdfs,
                        current_processing_file: data.data.current,
                        project_name: data.data.project_name});
                }else {
                    if(data.status === 'Loading'){
                        this.setState({msg_text: data.msg});
                    }else {
                        toast.error('An error occurred while calling the second API. Please try again.', {
                            position: 'top-left'
                        })
                    }
                }
            }
        } catch (error) {
            toast.error('An error occurred while calling the second API. Please try again.', {
                position: 'top-left'
            });
        }
    }

    startInterval(id) {
        // Start the interval for repeated API calls
        const intervalId = setInterval(async () => {
            // Call the second API here
            await this.callSecondApi(id);
        }, this.intervalDuration);

        // Store the interval ID in the state to clear it later if needed
        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts to avoid memory leaks
        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }


}

export default AddProject;
