import React, {Component} from 'react';

import Container from "react-bootstrap/Container";
import {Col, Image, Row} from "react-bootstrap";

import Footer from "./footer";
import Header from "./header";

class AboutUS extends Component {

    constructor(props) {
        super(props);

        this.state ={
            profile: null
        }
    }


    render() {

        return (
            <Container fluid className="p-0 pb-0" style={{ minHeight: '100vh', backgroundColor: '#0F0F0F' }}>
                <Header/>

                <div style={{ padding: '0px 10px', backgroundColor: '#0F0F0F', minHeight: '100vh' }} className={'mb-4 pb-4'}>


                    <Image
                        src="images/userguide.png"
                        fluid
                        className="img-fluid"
                        alt="Vect AI"
                        style={{ width: '100%', height: 'auto', maxHeight: '180px', objectFit: 'cover' }}
                    />



                    <div className={'m-3 p-4'}>
                        <p style={{color: 'white', textAlign: 'justify'}}>
                            At Vect we are redefining the way businesses in the construction industry tender projects.
                            <br/>
                            <br/>
                            With a deep understanding of the complexities involved in tendering processes, our innovative
                            solutions harness the power of artificial intelligence to streamline and optimise the entire
                            process.
                            <br/>
                            <br/>
                            From digesting tender packs and identifying clashes to generating tender queries and mitigating
                            risks, our tool revolutionises the way organisations approach construction projects. By
                            automating tedious tasks and providing real-time insights, we enable businesses to operate at
                            peak efficiency, saving valuable time and resources.
                            <br/>
                            <br/>
                            Our team of AI experts and construction professionals has meticulously developed and fine-tuned
                            Vect to address the unique challenges faced by the construction industry.
                            <br/>
                            <br/>
                            With our solution, businesses gain a competitive edge, enhance their decision-making
                            capabilities, and successfully navigate the complexities of construction tendering.
                        </p>
                    </div>

                </div>
                <Footer/>
            </Container>

        );
    }
}

export default AboutUS;
